<template lang="pug">
a.comments-crumb(
  :href="link"
  @click="onClickCommentIcon"
  :target="target"
)
  SvgAsset(:content="commentIconContent")
  
  ClientOnly
    span(v-if="showCounter") {{ count }}
</template>

<script lang="ts">
  import { type PropType } from 'vue';
  import { getRelativeLink } from '~/utils';
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';
  import commentIconContent from '~/assets/images/comment-icon.svg?raw';

  export default defineNuxtComponent({
    props: {
      link: {
        required: true,
        type: String as PropType<string>,
      },
      count: {
        required: true,
        type: Number as PropType<number>,
      },
      openCommentsInNewTab: {
        type: Boolean as PropType<boolean>,
        default: true,
      },
    },
    components: {
      SvgAsset,
    },

    computed: {
      commentIconContent() {
        return commentIconContent;
      },
      target(): string | null {
        return this.openCommentsInNewTab ? '_blank' : null;
      },
      showCounter() {
        return this.count !== 0;
      },
    },

    methods: {
      getRelativeLink,

      onClickCommentIcon(e: Event) {
        this.$emit('onClickCommentIcon', e);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .comments-crumb {
    display: flex;
    text-decoration: none;
    cursor: pointer;
    align-items: center;

    :deep(.svg-asset) {
      height: 24px;
      width: 24px;
      svg {
        stroke: #969698;
        stroke-width: 2px;
        fill: none;
      }
    }

    @include hover {
      span {
        color: black;
      }
      svg {
        stroke: black;
      }
    }

    span {
      @include fontSmallTextBtn;

      color: #5c5c5c;
      margin-left: 2px;
    }
  }
</style>
